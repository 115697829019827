<template>
    <VPage title="สรุปรายงานการเงิน">
        <a-row :gutter="16">
            <a-col :span="12">
                <CardStadiumReport
                    :start="startCurrentBillDate"
                    :end="endCurrentBillDate"
                    :start-of-last-report="startCurrentBillDate.clone().subtract(1,'week')"
                    :end-of-last-report="endCurrentBillDate.clone().subtract(1,'week')"
                    :enable-financial-mode="true"
                    title="รอบนี้" />
            </a-col>
            <a-col :span="12">
                <CardStadiumReport
                    :start="startLastBillDate"
                    :end="endLastBillDate"
                    :start-of-last-report="startLastBillDate.clone().subtract(1,'week')"
                    :end-of-last-report="endLastBillDate.clone().subtract(1,'week')"
                    :enable-financial-mode="true"
                    title="รอบที่แล้ว" />
            </a-col>
        </a-row>
        <br>
        <h2>
            ดูรายงานตามเวลาที่เลือก
        </h2>
        <br>
        <a-week-picker placeholder="เลือกสัปดาห์" @change="onWeekChange" />
        <br>
        <br>
        <CardStadiumReport
            v-if=" customReportStart !== null && customReportEnd != null"
            :start="customReportStart"
            :end="customReportEnd"
            :enable-financial-mode="true"
            :start-of-last-report="lastCustomReportStart"
            :end-of-last-report="lastCustomReportEnd" />
    </VPage>
</template>

<script>
import VPage from '@/components/VPage.vue'
import CardStadiumReport from '@/components/CardStadiumReport.vue'
import moment from 'moment'

export default {
    components: {
        VPage,
        CardStadiumReport,
    },
    data () {
        return {
            startCurrentBillDate: moment().startOf('week'),
            endCurrentBillDate: moment().endOf('week'),
            startLastBillDate: moment().subtract(7, 'days').startOf('week'),
            endLastBillDate: moment().subtract(7, 'days').endOf('week'),
            customReportStart: null,
            customReportEnd: null,
            lastCustomReportStart: null,
            lastCustomReportEnd: null,
        }
    },
    methods: {
        onWeekChange (e) {
            const dateInWeek = e
            this.customReportStart = dateInWeek.clone().startOf('week')
            this.customReportEnd = dateInWeek.clone().endOf('week')
            this.lastCustomReportStart = this.customReportStart.clone().subtract(1, 'week')
            this.lastCustomReportEnd = this.customReportEnd.clone().subtract(1, 'week')
        },
    },
}
</script>
