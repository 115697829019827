var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "สรุปรายงานการเงิน" } },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("CardStadiumReport", {
                attrs: {
                  start: _vm.startCurrentBillDate,
                  end: _vm.endCurrentBillDate,
                  "start-of-last-report": _vm.startCurrentBillDate
                    .clone()
                    .subtract(1, "week"),
                  "end-of-last-report": _vm.endCurrentBillDate
                    .clone()
                    .subtract(1, "week"),
                  "enable-financial-mode": true,
                  title: "รอบนี้"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("CardStadiumReport", {
                attrs: {
                  start: _vm.startLastBillDate,
                  end: _vm.endLastBillDate,
                  "start-of-last-report": _vm.startLastBillDate
                    .clone()
                    .subtract(1, "week"),
                  "end-of-last-report": _vm.endLastBillDate
                    .clone()
                    .subtract(1, "week"),
                  "enable-financial-mode": true,
                  title: "รอบที่แล้ว"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c("h2", [_vm._v("\n        ดูรายงานตามเวลาที่เลือก\n    ")]),
      _c("br"),
      _c("a-week-picker", {
        attrs: { placeholder: "เลือกสัปดาห์" },
        on: { change: _vm.onWeekChange }
      }),
      _c("br"),
      _c("br"),
      _vm.customReportStart !== null && _vm.customReportEnd != null
        ? _c("CardStadiumReport", {
            attrs: {
              start: _vm.customReportStart,
              end: _vm.customReportEnd,
              "enable-financial-mode": true,
              "start-of-last-report": _vm.lastCustomReportStart,
              "end-of-last-report": _vm.lastCustomReportEnd
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }